import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { getPage } from '../../api/wagtail';
import { backendDateFormat } from '../../consts/date-formats';
import initDateRangeState from '../../consts/initDateRangeState';
import { DDNC_NEWS_URL } from '../../consts/urls';
import useOnOuterClick from '../../hooks/useOnOuterClick';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FilterField } from '../../shared-styled/blocks/SearchAndFiltersBlocks';
import getRangeString from '../../utils/getRangeString';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import {
  CalendarIcon,
  CloseIcon,
  DateRangeWrapper,
  Input,
  Label,
  Wrapper,
} from './DateRangePIcker.styled';

const DateRangePicker = ({ className }) => {
  const router = useRouter();

  const {
    setNewsItems,
    setReportItems,
    dateRangeState,
    setDateRangeState,
    setItemsLoading,
  } = useGlobalContext();
  const [isActivated, setIsActivated] = useState(false);
  const isFilled = !!dateRangeState[0].startDate;

  const handeInputClick = () => setIsActivated(!isActivated);
  const handleCloseClick = (e) => {
    e.stopPropagation();

    if (router.asPath.includes(DDNC_NEWS_URL)) {
      router.push('/news');
    }

    setDateRangeState([initDateRangeState]);
  };

  useEffect(async () => {
    const params = isFilled
      ? {
          from_date: dayjs(dateRangeState[0].startDate).format(
            backendDateFormat,
          ),
          to_date: dayjs(dateRangeState[0].endDate).format(backendDateFormat),
        }
      : {};

    if (isActivated && router.asPath.includes(DDNC_NEWS_URL)) {
      await router.push('/news');
    }

    // load items
    setItemsLoading(true);

    if (router.asPath.includes('news')) {
      const { json: pageData } = (await getPage('/news', params)) || {};
      setNewsItems(pageData?.componentProps?.items?.results || []);
    } else {
      const { json: pageData } = (await getPage('/reports', params)) || {};
      setReportItems(pageData?.componentProps?.items?.results || []);
    }

    setItemsLoading(false);
  }, [dateRangeState]);

  const dateRange = useRef(null);
  useOnOuterClick(dateRange, () => setIsActivated(false), isActivated);

  return (
    <Wrapper className={className}>
      <FilterField $isWrapped={true} onClick={handeInputClick}>
        <Label>Date Range</Label>
        <Input>
          <span>
            {getRangeString(
              dateRangeState[0].startDate,
              dateRangeState[0].endDate,
              isFilled,
            )}
          </span>
          {isFilled && <CloseIcon onClick={handleCloseClick} />}
        </Input>
        <CalendarIcon />
      </FilterField>
      {isActivated && (
        <DateRangeWrapper ref={dateRange}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setDateRangeState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRangeState}
            rangeColors={['#379EA3', '#85CC9F', '#F0A20B']}
          />
        </DateRangeWrapper>
      )}
    </Wrapper>
  );
};

export default DateRangePicker;
